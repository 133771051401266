import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function EmployeeStep3({ employeeGoals, prevStep, saveForLater, setEmployeeGoals, submitData, userRole }) {
    const [isEditingGoals, setIsEditingGoals] = useState(false);
    const [editedGoals, setEditedGoals] = useState(employeeGoals.map(goal => goal.goal_text || ''));

    const navigate = useNavigate();

    useEffect(() => {
        setEditedGoals(employeeGoals.map(goal => goal.goal_text || ''));
    }, [employeeGoals]);

    const toggleEditGoals = () => {
        if (isEditingGoals) {
            const nonEmptyGoals = editedGoals
                .filter(goalText => goalText.trim() !== '')
                .map((goalText, index) => ({
                    ...employeeGoals[index],
                    goal_text: goalText,
                    confirmed: true
                }));
            setEmployeeGoals(nonEmptyGoals);
        }
        setIsEditingGoals(!isEditingGoals);
    };

    const handleGoalChange = (index, value) => {
        const newGoals = [...editedGoals];
        newGoals[index] = value;
        setEditedGoals(newGoals);
    };

    const handleSubmit = async () => {
        const nonEmptyGoals = editedGoals
            .filter(goalText => goalText && goalText.trim() !== '')
            .map((goalText, index) => ({
                ...employeeGoals[index],
                goal_text: goalText,
                confirmed: true
            }));

        const hasNullGoalText = nonEmptyGoals.some(goal => !goal.goal_text || goal.goal_text.trim() === '');
        if (hasNullGoalText) {
            alert('All goals must have text.');
            return;
        }

        try {
            const summarizedGoals = await Promise.all(nonEmptyGoals.map(async (goal) => {
                const response = await fetch('/api/summarization/summarize-goal', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ text: goal.goal_text })
                });
                const { summary } = await response.json();
                return {
                    ...goal,
                    summary: summary || goal.goal_text.substring(0, 100) + '...'
                };
            }));

            if (summarizedGoals.length === 0) {
                alert('You must submit a goal to continue');
                return;
            }

            submitData(summarizedGoals);

            alert('Data submitted successfully!');

            if (userRole === 'Admin') {
                window.scrollTo(0, 0);
                navigate('/adminportal');
            } else if (userRole === 'Manager') {
                window.scrollTo(0, 0);
                navigate('/managerportal');
            } else {
                window.scrollTo(0, 0);
                navigate('/employeeportal');
            }
        } catch (error) {
            console.error('Error summarizing goals:', error);
            alert('There was an error summarizing your goals. Please try again.');
        }
    };

    return (
        <div className='employee-goal-set-container'>
            <h2>Review Your Personal Goals</h2>
            <div className='employee-goal-set-review-container'>
                <h3>Personal Goals</h3>
                <div className='employee-goal-set-flex-container'>
                    {isEditingGoals ? (
                        <ul>
                            {editedGoals.map((goalText, index) => (
                                <li key={index} className='employee-goal-set-review-list-edit'>
                                    <textarea
                                        className='employee-goal-set-input'
                                        value={goalText}
                                        onChange={(e) => handleGoalChange(index, e.target.value)}
                                    />
                                </li>
                            ))}
                            <div className='employee-goal-set-button-review-wrapper'>
                                <button onClick={toggleEditGoals} className='employee-goal-set-button'>Done Editing</button>
                            </div>
                        </ul>
                    ) : (
                        <ul className='employee-goal-set-review-list-text'>
                            {employeeGoals.length > 0 ? (
                                employeeGoals.map((goal, index) => (
                                    <li className='employee-goal-set-list-item' key={index}>
                                        {goal.goal_text}
                                    </li>
                                ))
                            ) : (
                                <p className='employee-goal-set-review-text'>No personal goals provided.</p>
                            )}
                        </ul>
                    )}
                </div>
            </div>
            {!isEditingGoals && (
                <button onClick={toggleEditGoals} className='employee-goal-set-edit-button'>Edit</button>
            )}
            <div className='employee-goal-set-button-container'>
                <button onClick={prevStep} className='employee-goal-set-link'>Back</button>
                <button onClick={saveForLater} className='employee-goal-set-link'>Save for Later</button>
                <button onClick={handleSubmit} className='employee-goal-set-submit-link'>Submit</button>
            </div>
        </div>
    );
}

export default EmployeeStep3;

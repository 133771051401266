import React, { useState, useEffect, useRef } from 'react';
import '../Styles/AdminPortal.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../../Contexts/userContext';
import { TabProvider, useTab } from '../../../Contexts/tabContext';

const AdminPortal = () => {
    const { activeTab, setActiveTab } = useTab('set-company-goals');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);
    const { userId } = useUser();
    const [hasGoals, setHasGoals] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tab = query.get('tab');
        if (tab) {
            setActiveTab(tab);
        } else {
            navigate('?tab=set-company-goals');
        }
    }, [location, setActiveTab, navigate]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (userId) {
            fetch(`/api/goals/get-goals/${userId}`)
                .then(response => response.json())
                .then(data => {
                    setHasGoals(data && data.length > 0);
                })
                .catch(error => {
                    console.error('Error retrieving goals:', error);
                });
        }
    }, [userId]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setIsSidebarOpen(false);
        navigate(`?tab=${tab}`);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'set-company-goals':
                return (
                    <div>
                        <h2>Set Company Goals</h2>
                        <p>Set universal goals for all employees and managers to see.</p>
                        <div className='goal-setting-button-container'>
                            <Link to={`/AdminMaster?step=0&tab=${activeTab}`}>
                                <button className='goal-setting-button'>Set Goals</button>
                            </Link>
                            <Link to={`/AdminMaster?step=2&tab=${activeTab}`}>
                                <button className='goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'employee-directory':
                return (
                    <div>
                        <h2>Employee Directory</h2>
                        <p>View and manage the employee directory.</p>
                        <Link to={`/EmployeeDirectory?tab=${activeTab}`}>
                            <button className='goal-setting-button'>View Directory</button>
                        </Link>
                    </div>
                );
            case 'billing-usage':
                return (
                    <div>
                        <h2>Billing & Usage</h2>
                        <p>View billing and usage statistics.</p>
                        <Link to={`/Billing?tab=${activeTab}`}>
                            <button className='goal-setting-button'>View Billing</button>
                        </Link>
                    </div>
                );
            case 'set-team-goals':
                return (
                    <div>
                        <h2>Set Team Goals</h2>
                        <p>Define goals for your team to achieve.</p>
                        <div className='goal-setting-button-container'>
                            <Link to={`/ManagerMaster?step=0&tab=${activeTab}`}>
                                <button className='goal-setting-button'>Set Team Goals</button>
                            </Link>
                            <Link to={`/ManagerMaster?step=2&tab=${activeTab}`}>
                                <button className='goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'your-team':
                return (
                    <div>
                        <h2>Your Team</h2>
                        <p>View and manage your team members.</p>
                        <Link to={`/TeamMembers?tab=${activeTab}`}>
                            <button className='goal-setting-button'>View Team</button>
                        </Link>
                    </div>
                );
            case 'set-your-goals':
                return (
                    <div>
                        <h2>{hasGoals ? 'Review Your Goals' : 'Set Your Goals'}</h2>
                        <p>{hasGoals ? 'Review your personal goals.' : 'Establish your personal goals.'}</p>
                        <Link to={`/EmployeeMaster?step=0&tab=${activeTab}`}>
                            <button className='goal-setting-button'>{hasGoals ? 'Review Goals' : 'Set Your Goals'}</button>
                        </Link>
                    </div>
                );
            case 'your-progress':
                return (
                    <div>
                        <h2>Your Progress</h2>
                        <p>View your progress.</p>
                        <Link to={`/ProgressPage?tab=${activeTab}`}>
                            <button className='goal-setting-button'>View Progress</button>
                        </Link>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
            <div className="admin-portal">
                <button 
                    className="admin-portal-menu-button" 
                    onClick={toggleSidebar}
                >
                    Menu
                </button>
                <div className="admin-portal-sidebar">
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('set-company-goals')}>Set Company Goals</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('employee-directory')}>Employee Directory</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('billing-usage')}>Billing & Usage</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('set-team-goals')}>Set Team Goals</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('your-team')}>Your Team</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('set-your-goals')}>Set Your Goals</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('your-progress')}>Your Progress</button>
                </div>
                <div ref={sidebarRef} className={`admin-portal-sidebar mobile ${isSidebarOpen ? 'open' : ''}`}>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('set-company-goals')}>Set Company Goals</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('employee-directory')}>Employee Directory</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('billing-usage')}>Billing & Usage</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('set-team-goals')}>Set Team Goals</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('your-team')}>Your Team</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('set-your-goals')}>Set Your Goals</button>
                    <button className="admin-portal-sidebar-button" onClick={() => handleTabClick('your-progress')}>Your Progress</button>
                </div>
                <div className="admin-portal-content">
                    {renderContent()}
                </div>
            </div>
    );
};

export default AdminPortal;
import React, { useEffect, useState } from 'react';

function AdminStep2({ companyGoals, setCompanyGoals, prevStep, nextStep, saveForLater, textareaRef, company_id }) {
    const [goals, setGoals] = useState(companyGoals);

    useEffect(() => {
        if (company_id && companyGoals.length === 0) { // Only fetch if goals are empty
            console.log('Fetching company goals for companyId:', company_id);
            fetch(`/api/company-goals/get-goals/${company_id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setGoals(data);
                    setCompanyGoals(data);
                })
                .catch(error => {
                    console.error('Error fetching company goals:', error);
                });
        }
    }, [company_id, companyGoals, setCompanyGoals]);

    const addCompanyGoal = () => {
        if (goals.length >= 5) {
            alert('You can only have up to 5 goals.');
            return;
        }
        const newCompanyGoals = [...goals, { text: '', confirmed: false }];
        setGoals(newCompanyGoals);
        setCompanyGoals(newCompanyGoals);
    };

    const handleCompanyGoalChange = (index, value) => {
        const newCompanyGoals = goals.map((goal, i) => 
            i === index ? { ...goal, text: value } : goal
        );
        setGoals(newCompanyGoals);
        setCompanyGoals(newCompanyGoals);
    };

    const toggleCompanyGoalConfirmation = (index) => {
        const newCompanyGoals = goals.map((goal, i) => 
            i === index ? { ...goal, confirmed: !goal.confirmed } : goal
        );
        setGoals(newCompanyGoals);
        setCompanyGoals(newCompanyGoals);
    };

    const deleteCompanyGoal = async (index) => {
        const goalToDelete = goals[index];
        if (goalToDelete.id) {
            try {
                const response = await fetch(`/api/company-goals/delete-goal/${goalToDelete.id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete goal');
                }

                console.log('Goal deleted successfully');
            } catch (error) {
                console.error('Error deleting goal:', error);
                return;
            }
        }

        const newCompanyGoals = goals.filter((_, i) => i !== index);
        setGoals(newCompanyGoals);
        setCompanyGoals(newCompanyGoals);
    };

    return (
        <div className='admin-goal-set-container'>
            <h2 className='admin-goal-set-title'>Set Your Company Goals</h2>
            <p className='admin-goal-set-description'>Please enter up to five company-wide goals below. These will be considered when creating personalized goals for your employees.</p>
            {goals.length > 0 ? (
                goals.map((goal, index) => (
                    <div key={index} className='admin-goal-set-flex-container'>
                        <textarea
                            className='admin-goal-set-input'
                            placeholder='Enter a company goal here'
                            value={goal.text}
                            onChange={(e) => handleCompanyGoalChange(index, e.target.value)}
                            ref={textareaRef}
                            readOnly={goal.confirmed}
                        />
                        <div className="admin-goal-set-button-wrapper">
                            <button onClick={() => toggleCompanyGoalConfirmation(index)} className={`admin-goal-set-confirm-button ${goal.confirmed ? 'admin-goal-set-edit-button' : ''}`}>
                                {goal.confirmed ? 'Edit' : 'Confirm'}
                            </button>
                            <button onClick={() => deleteCompanyGoal(index)} className='admin-goal-delete-button'>Delete</button>
                        </div>
                    </div>
                ))
            ) : (
                <p>No goals available. Please add some goals.</p>
            )}
            {goals.length < 5 && (
                <button onClick={addCompanyGoal} className='admin-goal-set-add-button'>Add Goal</button>
            )}
            <div className='admin-goal-set-button-container'>
                <button onClick={prevStep} className='admin-goal-set-link'>Back</button>
                <button onClick={saveForLater} className='admin-goal-set-link'>Save for Later</button>
                <button onClick={nextStep} className='admin-goal-set-link'>Next</button>
            </div>
        </div>
    );
}

export default AdminStep2;
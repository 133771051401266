// src/Pages/ForgotPassword/ForgotPasswordPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios'; // For making API calls
import './Styles/ForgotPassword.css';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/forgot-password', { email });
            setMessage(response.data.message); // Display success message
        } catch (error) {
            setMessage('Error sending reset code. Please try again.');
        }
    };

    return (
        <div className="forgot-password-body">
            <div className="forgot-password-box">
                <h1>Forgot Password</h1>
                <form onSubmit={handleSubmit}>
                <input 
                    className="forgot-password-input"
                    name="email"
                    id="email"
                    type="email" 
                    placeholder="Enter your email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    autoComplete="email"
                    required 
                />
                <p className="forgot-password-p">Enter the email address associated with your account and we'll send you a link to reset your password.</p>
                <button className="forgot-password-button" type="submit">Next</button>
                <p onClick={() => navigate(-1)} className="forgot-password-back-link">
                    Back
                </p>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
}

export default ForgotPassword;
import React, { useEffect, useState } from 'react';
import AIButton from '../../../Components/AIButton/AIButton';

function ManagerStep2({ teamGoals = [], setTeamGoals, prevStep, nextStep, saveForLater, textareaRef, userId }) {
    const [goals, setGoals] = useState(teamGoals);

    useEffect(() => {
        setGoals(teamGoals);
    }, [teamGoals]);

    const addTeamGoal = () => {
        if (goals.length >= 5) {
            alert('You can only have up to 5 goals.');
            return;
        }
        const newTeamGoals = [...goals, { id: null, goal_text: '', confirmed: false, userID: userId }];
        console.log('Adding new goal:', newTeamGoals);
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    const handleTeamGoalChange = (index, value) => {
        const newTeamGoals = goals.map((goal, i) => (i === index ? { ...goal, goal_text: value } : goal));
        console.log('Updated goal_text:', newTeamGoals[index].goal_text);
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    const toggleTeamGoalConfirmation = (index) => {
        const newTeamGoals = goals.map((goal, i) => (i === index ? { ...goal, confirmed: !goal.confirmed } : goal));
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    const deleteTeamGoal = async (index) => {
        const goalToDelete = goals[index];
        if (goalToDelete.id) {
            try {
                const response = await fetch(`/api/team-goals/delete-goal/${goalToDelete.id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete goal');
                }

                console.log('Goal deleted successfully');
            } catch (error) {
                console.error('Error deleting goal:', error);
                return;
            }
        }

        const newTeamGoals = goals.filter((_, i) => i !== index);
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    return (
        <div className='manager-goal-set-container'>
            <h2 className='manager-goal-set-title'>Set Your Team Goals</h2>
            <p className='manager-goal-set-description'>Please enter up to five team-wide goals below. These team goals will help your team members set better personal goals.</p>
            <AIButton userId={userId} onNewGoal={addTeamGoal}/>
            {goals.map((goal, index) => (
                <div key={index} className='manager-goal-set-flex-container'>
                    <textarea
                        className='manager-goal-set-input'
                        placeholder='Enter a team goal here'
                        value={goal.goal_text}
                        onChange={(e) => handleTeamGoalChange(index, e.target.value)}
                        ref={textareaRef}
                        readOnly={goal.confirmed}
                    />
                    <div className="manager-goal-set-button-wrapper">
                        <button onClick={() => toggleTeamGoalConfirmation(index)} className={`manager-goal-set-confirm-button ${goal.confirmed ? 'manager-goal-set-edit-button' : ''}`}>
                            {goal.confirmed ? 'Edit' : 'Confirm'}
                        </button>
                        <button onClick={() => deleteTeamGoal(index)} className='manager-goal-delete-button'>Delete</button>
                    </div>
                </div>
            ))}
            {goals.length < 5 && (
                <button onClick={() => addTeamGoal()} className='manager-goal-set-add-button'>Add Goal</button>
            )}
            <div className='manager-goal-set-button-container'>
                <button onClick={prevStep} className='manager-goal-set-link'>Back</button>
                <button onClick={saveForLater} className='manager-goal-set-link'>Save for Later</button>
                <button onClick={nextStep} className='manager-goal-set-link'>Next</button>
            </div>
        </div>
    );
}

export default ManagerStep2;
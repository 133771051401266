import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie'; // Importing js-cookie for cookie management
import './Styles/LogInPage.css';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import { useUser } from '../../Contexts/userContext'; // Import the User Context

function LogInPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation();
    const { userRole, setUserId, setUserRole, setCompanyId } = useUser(); // Get setUserId, setUserRole, and setCompanyId from context
    const hasNavigated = useRef(false); // Ref to track if navigation has occurred

    useEffect(() => {
        console.log('LogInPage component rendered');
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(process.env.REACT_APP_LOGIN_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error('Login failed');
            }
            const data = await response.json();
            if (data.message === 'Login successful') {
                const userId = Cookies.get('userId');
                const userRole = Cookies.get('userRole');
                const companyId = Cookies.get('company_id');

                console.log('Cookies:', { userId, userRole, companyId });

                setUserId(userId);
                setUserRole(userRole);
                setCompanyId(companyId);

                const rolePathMap = {
                    'Admin': '/adminportal',
                    'Manager': '/managerportal',
                    'Employee': '/employeeportal'
                };
                navigate(rolePathMap[userRole] || '/');
            } else {
                alert('Login failed');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            alert('Login failed');
        }
    };

    useEffect(() => {
        if (userRole && !hasNavigated.current) {
            console.log('Role from context:', userRole);
            const currentPath = location.pathname;
            let targetPath = '';

            if (userRole === 'Admin') {
                targetPath = '/adminportal';
            } else if (userRole === 'Manager') {
                targetPath = '/managerportal';
            } else {
                targetPath = '/employeeportal';
            }

            if (currentPath !== targetPath) {
                console.log(`Redirecting to ${targetPath}`);
                hasNavigated.current = true; // Set ref to true to prevent further navigation
                navigate(targetPath);
            }
        }
    }, [userRole, location.pathname, navigate]);

    const handleSSO = () => {
        // Handle SSO login logic here
        alert('Redirecting to SSO login...');
    };

    const handleForgotPassword = () => {
        // Redirect to ForgotPassword component
        navigate('/ForgotPassword'); // Update to use navigate
    };

    return (
        <div className="login-body">
            <form onSubmit={handleLogin}>
                <div className="login-box">
                    <h1>Log In</h1>
                    <p>Sign in to your Astellyst account.</p>
                    <div className="login-container">
                        <input 
                            placeholder="Email"
                            className="login-input"
                            type="email" 
                            name="email"
                            id="email"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            autoComplete="email"
                            required 
                        />
                    </div>
                    <div className="login-container">
                        <input 
                            placeholder="Password"
                            className="login-input"
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="forgot-password-container">
                        <p className="forgot-password-link" onClick={() => navigate('/ForgotPassword')}>
                            Forgot Password?
                        </p>
                    </div>
                    <div className="login-button-container">    
                        <button className="login-button" type="submit">Log In</button>
                    </div>
                    <div className="or-container">
                        <hr className="or-line" />
                        <span>or</span>
                        <hr className="or-line" />
                    </div>
                    <div className="login-sso-button-container">
                        <button className="login-sso-button" onClick={handleSSO}>Log In with SSO</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default LogInPage;

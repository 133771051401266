import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTab } from '../../../Contexts/tabContext';

function EmployeeStep1({ companyMission, companyGoals, teamGoals, nextStep, userRole }) {
    const navigate = useNavigate();
    const { activeTab } = useTab();

    const handleBackClick = () => {
        console.log('User Role:', userRole);
        let portalPath = '/employeeportal?tab=set-goals';

        if (userRole === 'Admin') {
            portalPath = '/adminportal?tab=set-your-goals';
        } else if (userRole === 'Manager') {
            portalPath = '/managerportal?tab=set-your-goals';
        }
        window.scrollTo(0, 0);
        navigate(`${portalPath}`);
    };

    return (
        <div className='employee-goal-set-container'>
            <h2 className='employee-goal-review-mission-title'>Company Mission</h2>
            <p className='employee-goal-review-text'>Remember to think about the company mission when setting your goals.</p>
            <p className='employee-goal-review-mission'>{companyMission}</p>
            
            <h2 className='employee-goal-review-goals-title'>Company Goals</h2>
            <p className='employee-goal-review-text'>These are goals your company is working towards. How can you help your company achieve these goals?</p>
            <ul className='employee-goal-review-goals-list'>
                {companyGoals.map((goal, index) => (
                    <li key={index} className='employee-goal-set-list-item'>{goal.text}</li>
                ))}
            </ul>

            <h2 className='employee-goal-review-goals-title'>Your Manager's Team Goals</h2>
            <p className='employee-goal-review-text'>These are goals your manager has set for your team. How can you help your team achieve these goals?</p>
            <ul className='employee-goal-review-goals-list'>
                {teamGoals.map((goal, index) => (
                    <li key={index} className='employee-goal-set-list-item'>{goal.text}</li>
                ))}
            </ul>
            <div className='employee-goal-set-button-container'>
                <button className='employee-goal-set-link' onClick={handleBackClick}>Back</button>
                <button className='employee-goal-set-link' onClick={nextStep}>Next</button>
            </div>
        </div>
    );
}

export default EmployeeStep1;

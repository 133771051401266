// src/components/SalesQuotePage.js
import React, { useState } from 'react';
import axios from 'axios';
import './Styles/SalesQuotePage.css'; // Import the CSS file

const SalesQuotePage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phoneNumber: '',
    employeeCount: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Use the environment variable for the API URL
    const url = process.env.REACT_APP_SALES_QUOTE_API_URL || process.env.REACT_APP_LOCAL_SALES_QUOTE_API_URL;

    axios.post(url, formData)
      .then((response) => {
        console.log('SUCCESS!', response.data);
        alert('Your quote request has been saved successfully!');
      })
      .catch((error) => {
        console.error('FAILED...', error);
        alert('There was an error saving your request. Please try again.');
      });
  };

  return (
    <div className="sales-quote-container">
      <h1 className="sales-quote-header">Request a Sales Quote</h1>
      <p className="sales-quote-description">Please fill out the form below to receive a personalized quote.</p>
      <form className="sales-quote-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="firstName">First Name:</label>
          <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required autoComplete="given-name" />
        </div>
        <div>
          <label htmlFor="lastName">Last Name:</label>
          <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required autoComplete="family-name" />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required autoComplete="email" />
        </div>
        <div>
          <label htmlFor="company">Company:</label>
          <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} required autoComplete="organization" />
        </div>
        <div>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input type="text" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} autoComplete="tel" />
        </div>
        <div>
          <label htmlFor="employeeCount">Employee Count:</label>
          <input type="number" id="employeeCount" name="employeeCount" value={formData.employeeCount} onChange={handleChange} required autoComplete="off" />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default SalesQuotePage;
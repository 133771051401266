import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTab } from '../../../Contexts/tabContext';

function AdminStep1({ companyMission, setCompanyMission, saveForLater, textareaRef, nextStep, company_id }) {
    const navigate = useNavigate();
    const { activeTab } = useTab();

    useEffect(() => {
        if (company_id && !companyMission.text) {
            fetch(`/api/company-missions/mission/${company_id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.mission) {
                        setCompanyMission({ text: data.mission, confirmed: true });
                    }
                })
                .catch(error => {
                    console.error('Error fetching company mission:', error);
                });
        } else if (!company_id) {
            console.error('Company ID is undefined');
        }
    }, [company_id, companyMission.text, setCompanyMission]);

    const toggleMissionConfirmation = () => {
        setCompanyMission(prevMission => ({
            ...prevMission,
            confirmed: !prevMission.confirmed
        }));
    };

    const handleBackClick = () => {
        window.scrollTo(0, 0);
        navigate(`/AdminPortal?tab=set-company-goals`);
    };

    return (
        <div className='admin-goal-set-container'>
            <h2 className='admin-goal-set-title'>Company Mission</h2>
            <p className='admin-goal-set-description'>
                Please enter your company mission statement below. It will help us tailor goals to your company.
            </p>
            <div className='admin-goal-set-flex-container'>
                <textarea
                    ref={textareaRef}
                    className='admin-goal-set-input'
                    placeholder='Enter your company mission'
                    value={companyMission.text}
                    onChange={(e) => setCompanyMission({ ...companyMission, text: e.target.value })}
                    readOnly={companyMission.confirmed}
                />
                <button
                    className='admin-goal-set-confirm-button'
                    onClick={toggleMissionConfirmation}
                >
                    {companyMission.confirmed ? 'Edit' : 'Confirm'}
                </button>
            </div>
            <div className='admin-goal-set-button-container'>
                <button className='admin-goal-set-link' onClick={handleBackClick}>
                    Back
                </button>
                <button className='admin-goal-set-link' onClick={saveForLater}>
                    Save for Later
                </button>
                <button className='admin-goal-set-link' onClick={nextStep}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default AdminStep1;
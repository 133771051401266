import axios from 'axios';

// Access the subscription endpoint URL from environment variables
const SUBSCRIBE_ENDPOINT_URL = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_LOCAL_SUBSCRIBE_ENDPOINT_URL
    : process.env.REACT_APP_SUBSCRIBE_ENDPOINT_URL;

export const subscribeToNewsletter = async (email) => {
    const trimmedEmail = email.trim(); // Trim any whitespace
    const data = {
        email: trimmedEmail, // Change this to 'email'
        status: 'subscribed'
    };

    try {
        const response = await axios.post(SUBSCRIBE_ENDPOINT_URL, data, {
            headers: {
                'Authorization': `apikey ${process.env.MAILCHIMP_API_KEY}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error subscribing to Mailchimp:', error.response ? error.response.data : error.message);
        throw error;
    }
};

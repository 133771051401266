import React, { useEffect, useState } from 'react';
import AIButton from '../../../Components/AIButton/AIButton';

function EmployeeStep2({ employeeGoals = [], setEmployeeGoals, prevStep, nextStep, saveForLater, textareaRef, userId }) {
    const [goals, setGoals] = useState(employeeGoals);

    useEffect(() => {
        setGoals(employeeGoals);
    }, [employeeGoals]);

    const addEmployeeGoal = () => {
        if (goals.length >= 5) {
            alert('You can only have up to 5 goals.');
            return;
        }
        const newEmployeeGoals = [...goals, { id: null, goal_text: '', confirmed: false, userID: userId }];
        console.log('Adding new goal:', newEmployeeGoals);
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    const handleEmployeeGoalChange = (index, value) => {
        const newEmployeeGoals = goals.map((goal, i) => (i === index ? { ...goal, goal_text: value } : goal));
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    const toggleEmployeeGoalConfirmation = (index) => {
        const newEmployeeGoals = goals.map((goal, i) => (i === index ? { ...goal, confirmed: !goal.confirmed } : goal));
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    const deleteEmployeeGoal = async (index) => {
        const goalToDelete = goals[index];
        if (goalToDelete.id) {
            try {
                const response = await fetch(`/api/goals/delete-goal/${goalToDelete.id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete goal');
                }

                console.log('Goal deleted successfully');
            } catch (error) {
                console.error('Error deleting goal:', error);
                return;
            }
        }

        const newEmployeeGoals = goals.filter((_, i) => i !== index);
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    return (
        <div className='employee-goal-set-container'>
            <h2 className='employee-goal-set-title'>Set Your Personal Goals</h2>
            <p className='employee-goal-set-description'>Please enter up to five personal goals below. These will help personalize your development and make them more relevant to your career path.</p>
            <AIButton userId={userId} onNewGoal={addEmployeeGoal}/>
            {goals.map((goal, index) => (
                <div key={index} className='employee-goal-set-flex-container'>
                    <textarea
                        className='employee-goal-set-input'
                        placeholder='Enter a personal goal here'
                        value={goal.goal_text}
                        onChange={(e) => handleEmployeeGoalChange(index, e.target.value)}
                        ref={textareaRef}
                        readOnly={goal.confirmed}
                    />
                    <div className="employee-goal-set-button-wrapper">
                        <button onClick={() => toggleEmployeeGoalConfirmation(index)} className={`employee-goal-set-confirm-button ${goal.confirmed ? 'employee-goal-set-edit-button' : ''}`}>
                            {goal.confirmed ? 'Edit' : 'Confirm'}
                        </button>
                        <button onClick={() => deleteEmployeeGoal(index)} className='employee-goal-delete-button'>Delete</button>
                    </div>
                </div>
            ))}
            {goals.length < 5 && (
                <button onClick={() => addEmployeeGoal()} className='employee-goal-set-add-button'>Add Goal</button>
            )}
            <div className='employee-goal-set-button-container'>
                <button onClick={prevStep} className='employee-goal-set-link'>Back</button>
                <button onClick={saveForLater} className='employee-goal-set-link'>Save for Later</button>
                <button onClick={nextStep} className='employee-goal-set-link'>Next</button>
            </div>
        </div>
    );
}

export default EmployeeStep2;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../Styles/EmployeeProfile.css';

const EmployeeProfile = () => {
    const { userId } = useParams();
    const [employee, setEmployee] = useState(null);

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await fetch(`/api/users/get-employee-details/${userId}`);
                const data = await response.json();
                setEmployee(data);
            } catch (error) {
                console.error('Error fetching employee details:', error);
            }
        };

        fetchEmployeeDetails();
    }, [userId]);

    if (!employee) return <div>Loading...</div>;

    return (
        <div className="employee-profile">
            <div className="employee-info">
                <h1>{employee.user.first_name} {employee.user.last_name}</h1>
                <p>{employee.user.email}</p>
                <h3>{employee.user.job_title}</h3>
                <p>{employee.user.job_description}</p>
                <button>Edit</button>
            </div>
            <div className="employee-direct-reports">
                <h2>Direct Reports</h2>
                <ul>
                    {employee.directReports.map(report => (
                        <li key={report.id}>{report.first_name} {report.last_name} - {report.job_title}</li>
                    ))}
                </ul>
            </div>
            <div className="employee-goals">
                <h2>Goals</h2>
                {employee.goals.map(goal => (
                    <div key={goal.id}>
                        <h4>{goal.summary}</h4>
                        <p>{goal.goal_text}</p>
                        <p>Progress: {goal.progress}%</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmployeeProfile;

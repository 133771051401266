import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AdminStep3({ companyMission, companyGoals, prevStep, saveForLater, submitData }) {
    const location = useLocation();
    const navigate = useNavigate();

    // Check if the user came from AdminPortal
    const cameFromAdminPortal = location.search.includes('tab=');

    return (
        <div className='admin-goal-set-container'>
            <h2>Review Your Information</h2>
            <div className='admin-goal-set-review-container'>
                <h3>Company Mission</h3>
                <div className='admin-goal-set-flex-container'>
                    <p className='admin-goal-set-review-text'>{companyMission.text || "No company mission provided."}</p>
                </div>
                <h3>Company Goals</h3>
                <div className='admin-goal-set-flex-container'>
                    <ul className='admin-goal-set-review-list-text'>
                        {companyGoals.length > 0 ? (
                            companyGoals.map((goal, index) => (
                                <li className='admin-goal-set-list-item' key={index}>{goal.text}</li>
                            ))
                        ) : (
                            <p className='admin-company-goal-set-review-text'>No company goals provided.</p>
                        )}
                    </ul>
                </div>
            </div>
            <div className='admin-goal-set-button-container'>
                <button onClick={prevStep} className='admin-goal-set-link'>Back</button>
                <button onClick={saveForLater} className='admin-goal-set-link'>Save for Later</button>
                <button onClick={() => {
                    console.log('Submitting data...'); // Log when submit is clicked
                    submitData();
                    navigate('/adminportal');
                }} className='admin-goal-set-submit-link'>Submit</button>
            </div>
        </div>
    );
}

export default AdminStep3;
import React, { useState } from 'react';
import './AIButton.css';

function AIButton({ userId, onNewGoal }) {
    const [loading, setLoading] = useState(false);

    const handleGenerateGoal = async () => {
        setLoading(true);
        try {
            const response = await fetch('/api/ai-goals/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ userId })
            });
            const data = await response.json();
            onNewGoal(data.goal);
        } catch (error) {
            console.error('Error generating goal:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <button onClick={handleGenerateGoal} disabled={loading} className='astellyst-ai-button'>
            {loading ? 'Generating...' : 'Astellyst AI'}
        </button>
    );
}

export default AIButton;

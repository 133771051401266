// src/Pages/ManagerPortal/TeamMembers.js
import React, { useState, useEffect } from 'react';
import '../Styles/TeamMembers.css';
import { useUser } from '../../../Contexts/userContext'; // Import the useUser hook

const TeamMembers = () => {
    const { userId } = useUser(); // Get the userId from the context
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await fetch(`/api/users/get-team-members/${userId}`);
                const data = await response.json();
                setTeamMembers(data);
            } catch (error) {
                console.error('Error fetching team members:', error);
            }
        };

        if (userId) {
            fetchTeamMembers();
        }
    }, [userId]);

    const handleBackLinkClick = () => {
        window.scrollTo(0, 0);
        window.history.back();
    };

    return (
        <div className='team-members-body'>
            <p onClick={handleBackLinkClick} className="back-link">
                Back
            </p>
            <h1 className='team-members-header'>Your Team Members</h1>
            <div className="team-members">
                {teamMembers.map(member => (
                    <div key={member.id} className="team-member">
                        <h2>{member.first_name} {member.last_name}</h2>
                        <p>{member.job_title}</p>
                        <p>{member.email}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamMembers;
import React, { useState } from 'react';
import { subscribeToNewsletter } from './MailChimpAPI';
import './Styles/NewsletterSignUp.css';

const SignUpForm = ({ className }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await subscribeToNewsletter(email);
            setMessage('Successfully subscribed!');
        } catch (error) {
            setMessage('Failed to subscribe. Please try again.');
        }
    };

    return (
        <div className="form-container">
            <form className={className} onSubmit={handleSubmit}>
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    autoComplete="email"
                />
                <button type="submit">Subscribe</button>
            </form>
            {message && <p className="centered-message">{message}</p>}
        </div>
    );
};

export default SignUpForm;

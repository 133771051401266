import React, { useState, useEffect } from 'react';
import '../Styles/EmployeeDirectory.css'; // Ensure this CSS file contains the necessary styles
import { useTab } from '../../../Contexts/tabContext';
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '../../../Contexts/userContext';

const EmployeeDirectory = () => {
    const [users, setUsers] = useState([]);
    const { activeTab } = useTab();
    const navigate = useNavigate();
    const { companyId } = useUser(); // Get company_id from context
    
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`/api/users/get-users-by-company/${companyId}`);
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        if (companyId) {
            fetchUsers();
        }
    }, [companyId]);

    const handleBackLinkClick = () => {
        navigate(`/AdminPortal?tab=${activeTab}`);
    };

    return (
        <div className='employee-directory-body'>
            <p onClick={handleBackLinkClick} className="back-link">
                Back
            </p>
            <div className="employee-directory">
                <h1>Employee Directory</h1>
                <ul>
                    {users.map(user => (
                        <li key={user.id}>
                            <Link to={`/EmployeeProfile/${user.id}`}>
                                {user.first_name} {user.last_name} - {user.job_title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default EmployeeDirectory;

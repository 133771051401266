import React, { useState, useEffect, useRef } from "react";
import '../Styles/ProgressPage.css';
import { attachAutoExpand, detachAutoExpand } from '../../../Util/autoExpand';
import { useNavigate } from "react-router-dom";
import { useUser } from '../../../Contexts/userContext';

function ProgressPage() {
    const [goals, setGoals] = useState([]);
    const [commentsVisible, setCommentsVisible] = useState([]);
    const [buttonText, setButtonText] = useState([]);
    const [comments, setComments] = useState([]);
    const [currentComment, setCurrentComment] = useState([]);
    const [goalTextVisible, setGoalTextVisible] = useState([]);

    const textareaRefs = useRef([]);
    const navigate = useNavigate();
    const { userId } = useUser();

    useEffect(() => {
        const fetchGoals = async () => {
            try {
                const response = await fetch(`/api/goals/get-goals/${userId}`);
                const data = await response.json();
                console.log("Fetched Goals:", data);
                setGoals(data);
                setComments(Array(data.length).fill(""));
                setCommentsVisible(Array(data.length).fill(false));
                setButtonText(Array(data.length).fill("Add Comment"));
                setCurrentComment(Array(data.length).fill(""));
            } catch (error) {
                console.error("Error fetching goals:", error);
            }
        };

        if (userId) {
            fetchGoals();
        }
    }, [userId]);

    const handleProgressChange = (index, newProgress) => {
        const updatedGoals = [...goals];
        updatedGoals[index].progress = newProgress;
        setGoals(updatedGoals);
    };

    const handleCommentToggle = (index) => {
        const updatedVisibility = [...commentsVisible];
        updatedVisibility[index] = !updatedVisibility[index];
        
        const updatedButtonText = [...buttonText];
        updatedButtonText[index] = updatedVisibility[index] ? "Confirm" : "Add Comment";

        setCommentsVisible(updatedVisibility);
        setButtonText(updatedButtonText);

        if (updatedVisibility[index]) {
            setCurrentComment((prev) => {
                const newComments = [...prev];
                newComments[index] = comments[index];
                return newComments;
            });
        }
    };

    const handleCommentSubmit = (index) => {
        const updatedComments = [...comments];
        updatedComments[index] = currentComment[index];
        setComments(updatedComments);
        setCurrentComment((prev) => {
            const newComments = [...prev];
            newComments[index] = "";
            return newComments;
        });
        handleCommentToggle(index);
    };

    useEffect(() => {
        const currentRefs = textareaRefs.current;
        commentsVisible.forEach((visible, index) => {
            if (visible && currentRefs[index]) {
                attachAutoExpand(currentRefs[index]);
            }
        });

        return () => {
            commentsVisible.forEach((visible, index) => {
                if (visible && currentRefs[index]) {
                    detachAutoExpand(currentRefs[index]);
                }
            });
        };
    }, [commentsVisible]);

    const handleSubmit = async () => {
        console.log("Goals:", goals);
        console.log("Comments:", comments);

        // Save all progress updates to the database
        try {
            await Promise.all(goals.map((goal, index) => {
                return fetch('/api/goals/update-progress', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        goalId: goal.id,
                        progress: goal.progress,
                        comment: comments[index] // Optional: include comment if needed
                    }),
                });
            }));
            console.log('Progress updates submitted successfully');
        } catch (error) {
            console.error('Error submitting progress updates:', error);
        }
    };

    const handleGoBack = () => {
        window.scrollTo(0, 0);
        navigate(-1);
    };

    const handleGoalTextToggle = (index) => {
        setGoalTextVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    return (
        <div className="progress-page-body">
            <header className="progress-page-header">
                <h2 className="progress-page-header-text">Goal Progress</h2>
            </header>
            <div className="progress-page-goals">
                {goals.map((goal, index) => (
                    <div key={index}>
                        <h5 className="progress-page-goal-name">{goal.summary || goal.goal_text}</h5>
                        {goalTextVisible[index] && (
                            <p className="progress-page-goal-text">{goal.goal_text}</p>
                        )}
                        <button 
                            className="progress-page-goal-text-toggle-button" 
                            onClick={() => handleGoalTextToggle(index)}
                        >
                            {goalTextVisible[index] ? "Show Less" : "Show More"}
                        </button>
                        <div className="progress-page-goal-progress-container">
                            <div className="progress-page-goal-progress-percentage-container">
                                <input
                                    className="progress-page-goal-progress"
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={goal.progress}
                                    onChange={(e) => handleProgressChange(index, e.target.value)}
                                />
                                <span className="progress-page-goal-progress-percentage">{goal.progress}%</span>
                            </div>
                            {!commentsVisible[index] && (
                                <button 
                                className={`progress-page-goal-comment-button ${commentsVisible[index] ? '' : 'hidden'}`} 
                                onClick={() => handleCommentToggle(index)}
                            >
                                {buttonText[index]}
                            </button>
                        )}
                        </div>
                        {commentsVisible[index] && (
                            <div className="progress-page-goal-comment-container">
                                <textarea
                                    ref={(el) => (textareaRefs.current[index] = el)}
                                    className="progress-page-goal-comment"
                                    placeholder="Add your comment here..."
                                    value={currentComment[index]}
                                    onChange={(e) => {
                                        const updatedCurrentComment = [...currentComment];
                                        updatedCurrentComment[index] = e.target.value;
                                        setCurrentComment(updatedCurrentComment);
                                    }}
                                />
                                <button className="progress-page-goal-confirm-button" onClick={() => handleCommentSubmit(index)}>Confirm</button>
                            </div>
                        )}
                        {comments[index] && (
                            <p className="progress-page-goal-comment-display">{comments[index]}</p>
                        )}
                    </div>
                ))}
            </div>
            <div className="progress-page-submit-container">
            <button className="progress-page-link" onClick={handleGoBack}>
                    Go Back
                </button>
                <button className="progress-page-submit-link" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
}

export default ProgressPage;

import React, { useState, useRef, useEffect, useMemo } from 'react';
import ManagerStep1 from './ManagerStep1';
import ManagerStep2 from './ManagerStep2';
import ManagerStep3 from './ManagerStep3';
import '../Styles/ManagerMaster.css';
import { attachAutoExpand, detachAutoExpand } from '../../../Util/autoExpand';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../../Contexts/userContext';

function ManagerMaster() {
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const initialStep = parseInt(queryParams.get('step'), 10);

    const [currentStep, setCurrentStep] = useState(initialStep || 0);
    const [companyMission, setCompanyMission] = useState('');
    const [companyGoals, setCompanyGoals] = useState([]);
    const [teamGoals, setTeamGoals] = useState([]);

    const textareaRef = useRef(null);
    const { userId, userRole, companyId } = useUser();
    const managerId = userId;
    console.log('User context:', { userId, userRole, companyId, managerId });

    useEffect(() => {
        if (companyId) {
            console.log('Fetching company mission and goals for companyId:', companyId);
            fetch(`/api/company-missions/mission/${companyId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => setCompanyMission(data.mission))
                .catch(error => {
                    console.error('Error fetching company mission:', error);
                });

            fetch(`/api/company-goals/get-goals/${companyId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => setCompanyGoals(data))
                .catch(error => {
                    console.error('Error fetching company goals:', error);
                });
        }
    }, [companyId]);

    useEffect(() => {
        if (managerId) {
            console.log('Fetching team goals for managerId:', managerId);
            fetch(`/api/team-goals/get-goals/${managerId}`)
                .then(response => response.json())
                .then(apiGoals => {
                    console.log('Fetched goals from API:', apiGoals);
                    setTeamGoals(apiGoals);
                })
                .catch(error => {
                    console.error('Error retrieving team goals:', error);
                    setTeamGoals([]);
                });
        }
    }, [managerId]);

    useEffect(() => {
        if (textareaRef.current) {
            attachAutoExpand(textareaRef.current);
        }
        return () => {
            if (textareaRef.current) {
                detachAutoExpand(textareaRef.current);
            }
        };
    }, [textareaRef]);

    useEffect(() => {
        const handlePopState = (event) => {
            const step = event.state?.step || 0;
            console.log('Popstate event, setting current step:', step);
            setCurrentStep(step);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const updateStep = (newStep) => {
        console.log('Updating step to:', newStep);
        setCurrentStep(newStep);
        window.history.pushState({ step: newStep }, '', `?step=${newStep}`);
    };

    const nextStep = () => updateStep(currentStep + 1);
    const prevStep = () => updateStep(currentStep - 1);

    const saveForLater = () => {
        alert('Data saved for later!');
    };

    const submitTeamGoals = (summarizedGoals) => {
        const newGoals = summarizedGoals.filter(goal => !goal.id);
        const existingGoals = summarizedGoals.filter(goal => goal.id);

        console.log('New Goals:', newGoals);
        console.log('Existing Goals:', existingGoals);

        if (newGoals.length > 0) {
            fetch('/api/team-goals/create-goals', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ managerId: userId, goals: newGoals }),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('New goals submitted successfully:', data);
            })
            .catch(error => {
                console.error('Error submitting new goals:', error);
            });
        }

        if (existingGoals.length > 0) {
            fetch('/api/team-goals/update-goals', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ managerId: userId, goals: existingGoals }),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Existing goals updated successfully:', data);
            })
            .catch(error => {
                console.error('Error updating existing goals:', error);
            });
        }
    };

    return (
        <div className='manager-goal-set-body'>
            {currentStep === 0 && (
                <ManagerStep1
                    companyMission={companyMission}
                    companyGoals={companyGoals}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    textareaRef={textareaRef}
                    userRole={userRole}
                />
            )}
            {currentStep === 1 && (
                <ManagerStep2
                    teamGoals={teamGoals}
                    setTeamGoals={setTeamGoals}
                    textareaRef={textareaRef}
                    prevStep={prevStep}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    userId={userId}
                />
            )}
            {currentStep === 2 && (
                <ManagerStep3
                    teamGoals={teamGoals}
                    setTeamGoals={setTeamGoals}
                    prevStep={prevStep}
                    submitData={submitTeamGoals}
                    saveForLater={saveForLater}
                    userRole={userRole}
                />
            )}
        </div>
    );
}

export default ManagerMaster;

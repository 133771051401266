import React from 'react';
import { Link } from 'react-router-dom';
import './Styles/Contact.css';

const Contact = () => {
    return (
        <div className='contact-body'>
            <h1>Want to get in touch?</h1>
            <div className="contact-container">
                <div className="contact-box">
                    <h2>Sales</h2>
                    <p>Get a quote or learn about our products</p>
                    <Link to="/SalesQuotePage" className="contact-button">Sales</Link>
                </div>
                <div className="contact-box">
                    <h2>Support</h2>
                    <p>Get help with your account</p>
                    <Link to="/Support" className="contact-button">Support</Link>
                </div>
            </div>
                <div className="contact-h3-container">
                <h3 className="contact-h3">For media inquiries and more, please contact us at <a href="mailto:info@astellyst.com">info@astellyst.com</a></h3>
            </div>
        </div>
    );
};

export default Contact;

import React, { useState, useEffect, useRef } from 'react';
import '../Styles/ManagerPortal.css'; // Updated CSS import
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../../Contexts/userContext';
import { useTab } from '../../../Contexts/tabContext';

const ManagerPortal = () => {
    const { activeTab, setActiveTab } = useTab('set-goals');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);
    const { userId } = useUser();
    const [hasGoals, setHasGoals] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tab = query.get('tab');
        if (tab) {
            setActiveTab(tab);
        } else {
            navigate('?tab=set-goals');
        }
    }, [location, setActiveTab, navigate]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetch(`/api/goals/get-goals/${userId}`)
            .then(response => response.json())
            .then(data => {
                setHasGoals(data && data.length > 0);
            })
            .catch(error => {
                console.error('Error retrieving goals:', error);
            });
    }, [userId]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setIsSidebarOpen(false);
        navigate(`?tab=${tab}`);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'set-goals':
                return (
                    <div>
                        <h2>Set Team Goals</h2>
                        <p>Define goals for your team to achieve.</p>
                        <div className='goal-setting-button-container'>
                            <Link to={`/ManagerMaster?step=0&tab=${activeTab}`}>
                                <button className='goal-setting-button'>Set Team Goals</button>
                            </Link>
                            <Link to={`/ManagerMaster?step=2&tab=${activeTab}`}>
                                <button className='goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'your-team':
                return (
                    <div>
                        <h2>Your Team</h2>
                        <p>View and manage your team members.</p>
                        <Link to={`/TeamMembers?tab=${activeTab}`}>
                            <button className='goal-setting-button'>View Team</button>
                        </Link>
                    </div>
                );
            case 'set-your-goals':
                return (
                    <div>
                        <h2>{hasGoals ? 'Review Your Goals' : 'Set Your Goals'}</h2>
                        <p>{hasGoals ? 'Review your personal goals.' : 'Establish your personal goals.'}</p>
                        <Link to={`/EmployeeMaster?step=0&tab=${activeTab}`}>
                            <button className='goal-setting-button'>{hasGoals ? 'Review Goals' : 'Set Your Goals'}</button>
                        </Link>
                    </div>
                );
            case 'your-progress':
                return (
                    <div>
                        <h2>Your Progress</h2>
                        <p>View your progress.</p>
                        <Link to={`/ProgressPage?tab=${activeTab}`}>
                            <button className='goal-setting-button'>View Progress</button>
                        </Link>
                    </div>
                );
            default:
                return <div>Please select a tab.</div>;
        }
    };

    return (
        <div className="manager-portal">
            <button 
                className="manager-portal-menu-button" 
                onClick={toggleSidebar}
            >
                Menu
            </button>
            <div className="manager-portal-sidebar">
                <button className="manager-portal-sidebar-button" onClick={() => handleTabClick('set-goals')}>Set Team Goals</button>
                <button className="manager-portal-sidebar-button" onClick={() => handleTabClick('your-team')}>Your Team</button>
                <button className="manager-portal-sidebar-button" onClick={() => handleTabClick('set-your-goals')}>Set Your Goals</button>
                <button className="manager-portal-sidebar-button" onClick={() => handleTabClick('your-progress')}>Your Progress</button>
            </div>
            <div ref={sidebarRef} className={`manager-portal-sidebar mobile ${isSidebarOpen ? 'open' : ''}`}>
                <button className="manager-portal-sidebar-button" onClick={() => handleTabClick('set-goals')}>Set Team Goals</button>
                <button className="manager-portal-sidebar-button" onClick={() => handleTabClick('your-team')}>Your Team</button>
                <button className="manager-portal-sidebar-button" onClick={() => handleTabClick('set-your-goals')}>Set Your Goals</button>
                <button className="manager-portal-sidebar-button" onClick={() => handleTabClick('your-progress')}>Your Progress</button>
            </div>
            <div className="manager-portal-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default ManagerPortal;